<template>
  <ion-content>
    <div class="title-container">
      <ion-row class="title-content">
        <ion-col class="title-text-only">
          <div class="title-content-avatar">
            <ion-avatar class="title-content-avatar-image">
              <img src="@/assets/misc/placeholder.png" v-if="isPageLoading === true">
							<img :src="picture" v-if="isPageLoading === false">
            </ion-avatar>
          </div>
          <div class="title-content-text">
            <p class="title-content-subheader"><span class="title-content-notice">My Profile</span></p>
            <h1 class="title-content-name" v-if="isPageLoading === false">{{firstName}} {{lastName}}</h1>
            <h1 class="title-content-name" v-if="isPageLoading === true">Loading, please wait....</h1>
          </div>
        </ion-col>
        <ion-col class="title-action-button" v-if="isPageLoading === false">
          <ion-button color="light" @click="toggleUpdateMode">{{isViewMode ? 'Update Profile' : 'Discard Changes'}}</ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div class="content-container" v-if="isPageLoading === false">
      <ion-card class="content-container-card">
          <ion-card-header class="content-card-title">
              <h1>Your identity:</h1>
          </ion-card-header>
          <ion-card-content>
              <ion-card-subtitle>Email Address:</ion-card-subtitle>
              <ion-card-title class="content-card-data">
                  <b>{{emailAddress}}</b>
              </ion-card-title>
          </ion-card-content>
          <ion-card-header>
              <ion-card-subtitle>Employee Number:</ion-card-subtitle>
              <ion-card-title class="content-card-data">
                {{employeeNo}}
              </ion-card-title>
          </ion-card-header>
      </ion-card>
      <ion-card class="content-container-card">
          <ion-card-header class="content-card-title">
              <h1>Basic Information:</h1>
          </ion-card-header>
          <ion-card-content>
              <ion-row class="content-name-container">
                  <ion-col>
                      <ion-card-subtitle>First Name:</ion-card-subtitle>
                      <ion-card-title>
                          <ion-input
                          class="onboard-input"
                          :readonly="isViewMode"
                          :value="firstName"
                          @ionInput="firstName = $event.target.value;"
                          placeholder="First Name"></ion-input>
                      </ion-card-title>
                  </ion-col>
                  <ion-col>
                      <ion-card-subtitle>Last Name:</ion-card-subtitle>
                      <ion-card-title>
                          <ion-input 
                          class="onboard-input"
                          :readonly="isViewMode"
                          :value="lastName"
                          @ionInput="lastName = $event.target.value;"
                          placeholder="Last Name"></ion-input>
                      </ion-card-title>
                  </ion-col>
              </ion-row>
              <ion-row class="content-name-container">
                  <ion-col>
                      <ion-card-subtitle>Middle Name:</ion-card-subtitle>
                      <ion-card-title>
                          <ion-input
                          class="onboard-input"
                          :readonly="isViewMode"
                          :value="middleName"
                          @ionInput="middleName = $event.target.value;"
                          placeholder="First Name"></ion-input>
                      </ion-card-title>
                  </ion-col>
                  <ion-col>
                      <ion-card-subtitle>Suffix:</ion-card-subtitle>
                      <ion-card-title>
                          <ion-input 
                          class="onboard-input"
                          :readonly="isViewMode"
                          :value="suffix"
                          @ionInput="suffix = $event.target.value;"
                          placeholder="Suffix"></ion-input>
                      </ion-card-title>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col>
                      <ion-card-subtitle>Work Category:</ion-card-subtitle>
                      <ion-item class="content-card-select">
                          <!-- <ion-label>Work Category</ion-label> -->
                          <ion-select
                          v-if="isViewMode === false"
                          :value="workCategory"
                          class="content-card-dropdown"
                          placeholder="Select your work category"
                          @ionChange="workCategory = $event.target.value;"
                          ok-text="Confirm"
                          cancel-text="Dismiss"
                          interface="action-sheet">
                              <ion-select-option value="Work From Home">Work From Home</ion-select-option>
                              <ion-select-option value="Skeletal - Head Office">Skeletal - Head Office</ion-select-option>
                              <ion-select-option value="Buusiness as Usual - Branches/Field">Business as Usual - Branches/Field</ion-select-option>
                          </ion-select>
                          <p class="content-view-mode-text" v-if="isViewMode">{{workCategory}}</p>
                      </ion-item>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col>
                      <ion-card-subtitle>Department:</ion-card-subtitle>
                      <ion-item class="content-card-select">
                          <!-- <ion-label>Department</ion-label> -->
                          <ion-select
                          v-if="isViewMode === false"
                          :value="dept"
                          class="content-card-dropdown"
                          placeholder="Select your department"
                          @ionChange="dept = $event.target.value;"
                          ok-text="Confirm"
                          cancel-text="Dismiss"
                          interface="action-sheet">
                              <ion-select-option v-for="(item, index) in departments" :key="index">{{item}}</ion-select-option>
                          </ion-select>
                          <p class="content-view-mode-text" v-if="isViewMode">{{dept}}</p>
                      </ion-item>
                  </ion-col>
              </ion-row>
              <ion-row>
                  <ion-col>
                      <ion-card-subtitle>Branch:</ion-card-subtitle>
                      <ion-card-title>
                          <ion-input
                          :readonly="isViewMode"
                          class="onboard-input"
                          :value="branch"
                          @ionInput="branch = $event.target.value;"
                          placeholder="Branch Assigned"></ion-input>
                      </ion-card-title>
                  </ion-col>
              </ion-row>
          </ion-card-content>
      </ion-card>
      <ion-card class="content-container-card">
        <ion-card-header>
          <ion-card-title>
            <ion-row class="card-action-container">
              <ion-card-subtitle class="card-action-title">Should you experience any of the symptoms, would you like us to set you up with a teleconsultation with the company physician?</ion-card-subtitle>
              <ion-card-title>
                <ion-list>
                  <ion-radio-group 
                    class="radio-container"
                    :value="teleconsultation"
                    @ionChange="teleconsultation = $event.target.value;"
                  >
                    <ion-item class="input-item-custom" lines="none">
                      <ion-radio v-if="isViewMode === false" class="radio-button" :value="true"></ion-radio>
                      <ion-icon v-if="isViewMode" class="radio-icon" :name="teleconsultation ? 'radio-button-on-sharp' : 'radio-button-off-sharp'"></ion-icon>
                      <ion-label>Yes</ion-label>
                    </ion-item>
                    <ion-item class="input-item-custom" lines="none">
                      <ion-radio v-if="isViewMode === false" class="radio-button" :value="false"></ion-radio>
                      <ion-icon v-if="isViewMode" class="radio-icon" :name="teleconsultation ? 'radio-button-off-sharp' : 'radio-button-on-sharp'"></ion-icon>
                      <ion-label>No</ion-label>
                    </ion-item>
                  </ion-radio-group>
                </ion-list>
              </ion-card-title>              
            </ion-row>
          </ion-card-title>
        </ion-card-header>
      </ion-card>
      <div class="button-container" v-if="isViewMode === false">
        <ion-button @click="presentAlertCheckbox"><b>Update</b></ion-button>
      </div>
    </div>
    <div class="content-container" v-if="isPageLoading === true">
      <ion-card class="content-container-card">
        <ion-card-content>
          <ion-row class="message-content-container">
            <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
          </ion-row>
        </ion-card-content>
      </ion-card>
      <ion-card class="content-container-card">
        <ion-card-content>
          <ion-row class="message-content-container">
            <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
          </ion-row>
        </ion-card-content>
      </ion-card>
      <ion-card class="content-container-card">
        <ion-card-content>
          <ion-row class="message-content-container">
            <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 80%;"></ion-skeleton-text>
            <ion-skeleton-text end="end" animated style="width: 60%;"></ion-skeleton-text>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </div>
  </ion-content>
</template>
<script>

import { alertController, loadingController, toastController, IonAvatar, IonCol, IonButton, IonRow, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonInput, IonCard, IonSelectOption, IonSelect, IonItem, IonRadio, IonIcon, IonLabel, IonRadioGroup, IonList, IonSkeletonText, IonContent  } from '@ionic/vue'
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';
import store from '../store/index';

export default {
  name: 'ProfileContainer',
  components: {
    IonAvatar, IonCol, IonButton, IonRow, IonCardHeader,
    IonCardSubtitle, IonCardTitle, IonCardContent, IonInput,
    IonCard, IonSelectOption, IonSelect, IonItem, IonRadio,
    IonIcon, IonLabel, IonRadioGroup, IonList,
    IonSkeletonText, IonContent
  },
  props: {
    getUserInfo: {
      type: Object
    },
    isPageLoading: {
			type: Boolean
		},
  },
  data() {
    return {
      emailAddress: '',
      firstName: '',
      lastName: '',
      middleName: '',
      suffix: '',
      workCategory: '',
      dept: '',
      teleconsultation: '',
      employeeNo: '',
      branch: '',
      userInfo: {},
      isViewMode: true,
      picture: '@/assets/misc/placeholder.png',
      departments: [
        'Ops Branches',
        'Ops Head Office',
        'GSD',
        'CS',
        'Marketing',
        'SAM',
        'LSG - Head Office',
        'LSG - Field',
        'ITD',
        'Forex',
        'Global Business Solutions',
        'Finance',
        'AVP',
        'HRD',
        'Audit',
        'Legal and Compliance',
        'Risk Management',
        'Product Management',
        'Office of the President',
        'Digital Business'
      ]
    };
  },
  watch: {
    getUserInfo: {
			handler(val) {
        this.userInfo = val;
        this.populateUserData(val);
      },
			deep: true
		},
  },
  created() {
    this.userInfo = this.getUserInfo;
    this.populateUserData(this.getUserInfo);
  },
  methods: {
    toggleUpdateMode() {
			if (this.isViewMode === false) {
				this.returnToViewMode();
			} else {
				this.isViewMode = false;
			}
		},
    async dismissLoading() {
      const loading = await loadingController;

      loading.dismiss()
    },
    async presentLoading() {
      const loading = await loadingController
        .create({
          cssClass: 'loading-container',
          message: 'Please wait...',
          duration: this.timeout,
        });

      await loading.present();
    },
    async openToast(msg, type) {
      const toast = await toastController
        .create({
          message: msg,
          animated: true,
          duration: 5000,
          color: type,
          cssClass: 'toast-custom',
          end: true
        })
      return toast.present();
    },
    async presentAlertCheckbox() {
      const alert = await alertController.create({
        cssClass: 'alert-container',
        header: 'Update Profile',
        backdropDismiss: false,
        message: 'Are you sure you want to update your profile?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            },
          },
          {
            text: 'Continue',
            handler: () => {
              this.updateProfile();
            },
          },
        ],
      });

      return alert.present();
    },
    async updateProfile() {
      this.presentLoading();

      let cipherData = VueCookies.get('misc-hs');

      let bytes = ''
      let decryptedData = ''

      if (cipherData) {
        bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY)
        decryptedData = bytes.toString(CryptoJS.enc.Utf8)
      }

      let payload = {
        id: this.userInfo.id,
        data: {
          first_name: this.firstName,
          last_name: this.lastName,
          middle_name: this.middleName,
          suffix: this.suffix,
          email: this.emailAddress,
          employee_no: this.employeeNo,
          work_category: this.workCategory,
          assigned_department: this.dept,
          enable_teleconsultation: this.teleconsultation,
          branch: this.branch,
          status: '1',
          hs_token: decryptedData
        }
      };

      await store.dispatch('user/updateProfile', payload).then(res => {
        this.dismissLoading();
        const that = this;
        this.isViewMode = true;

        let currData = res.data.user;
        
        let bytes = "";
        let decryptedData = "";

        let cipherData = VueCookies.get('misc');

        if (cipherData === null  || cipherData === undefined || cipherData === '') {
          window.location.href = "/login";
        } else {
          bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
          decryptedData = bytes.toString(CryptoJS.enc.Utf8);

          if (decryptedData) {
            let decode = JSON.parse(decryptedData);
            let token = decode.token;
          
            //Check if user exists
            if (token) {
              let userData = {
                user: currData,
                token: token
              }

              //Update Cookies
              let encryptData = CryptoJS.AES.encrypt(JSON.stringify(userData), process.env.VUE_APP_DCRPT_KEY).toString();
              VueCookies.set("misc", encryptData, '1d', null, null, true, "Strict");
            } else {
              localStorage.setItem('is_session_expired', true);
              localStorage.removeItem("is_consent_agreed");
              VueCookies.remove("misc");
              VueCookies.remove("misc-hs");
              window.location.href = '/login';
            }
          } else {
            localStorage.setItem('is_session_expired', true);
            localStorage.removeItem("is_consent_agreed");
            VueCookies.remove("misc");
            VueCookies.remove("misc-hs");
            window.location.href = '/login';
          }
        }

        setTimeout(function() {
          that.openToast(`<b>${res.data.message}</b>`, 'success')
        }, 1000);
        
        this.$router.push('/dashboard')
      }).catch((err) => {
        this.dismissLoading();

        let errorFields = '';
        let errorMessage = err.data.errors;

        for (let key in errorMessage) {
          errorFields += errorMessage[key][0] + '<br>';
        }

        const that = this;

        setTimeout(function() {
          that.openToast('<b>Sorry, Invalid fields:</b><br><br>' + errorFields, 'danger')
        }, 1000);
      });
    },
    async returnToViewMode() {
			const alert = await alertController.create({
				cssClass: 'alert-container',
				header: 'Return to View Mode',
				backdropDismiss: false,
				message: 'Are you sure you want to return to viewing mode? Any unsaved changes will be lost.',
				buttons: [
					{
						text: 'No',
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
						},
					},
					{
						text: 'Yes',
						handler: () => {
							this.isViewMode = true;
							//Restores the item to current state
							this.populateUserData(this.userInfo);
						},
					},
				],
			});

			return alert.present();
		},
    populateUserData(data) {
      this.emailAddress = data.email;
      this.firstName = data.first_name;
      this.lastName = data.last_name;
      this.middleName = data.middle_name;
      this.suffix = data.suffix;
      this.dept = data.assigned_department;
      this.workCategory = data.work_category;
      this.teleconsultation = data.enable_teleconsultation != '' ? data.enable_teleconsultation : false;
      this.employeeNo = data.employee_no;
      this.branch = data.branch;
      this.picture = data.picture;
    }
  }
};
</script>
<style scoped>
  .title-container {
    padding: 25px 11px;
    
    background: rgb(2,0,36);
    background: linear-gradient(130deg, rgba(2,0,36,1) 0%, rgba(26,39,145,1) 35%, rgba(0,224,255,1) 100%);
  }
  
  .title-container h1 {
    font-weight: bolder;
    margin-top: 0;
  }

  .title-content-avatar {
    margin: auto 15px;
    margin-left: 0;
  }

  .title-content-subheader {
    margin-bottom: 5px;
  }

  .title-content-name {
    font-family: "Roboto Bold";
    font-size: 20px;
  }

  .title-text-only {
    display: flex;
    flex-direction: row;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .title-content-notice {
    font-family: "Roboto Medium";
  }

  .button-container {
    padding: 0 11px;
    margin-right: 7px;
  }

  .card-action-container {
    display: block;
  }

  .card-action-title {
    font-size: 18px;
  }

  .card-action-button { 
    text-align: left;
  }

  .toggler-button {
    margin-top: 15px;
    position: relative;
    left: -20px;
  }

  .onboard-input {
    border-bottom: 1px solid black;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-family: "Roboto Regular";
  }

  .onboard-input .native-input {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-family: "Roboto Regular";
  }

  .button-container {
    padding: 0;
  }

  .content-card-title h1 {
    margin: 10px 0;
    font-weight: bolder;
    color: #000;
    font-family: "Roboto Bold";
    font-size: 18px;
  }

  .content-card-select::part(native) {
    padding: 0 !important;
    margin: 0 !important;
    font-family: "Roboto Regular";
    border-bottom: 1px solid #000;
  }

  .content-card-data {
    font-size: 18px;
  }

  .content-card-select .content-card-dropdown {
    padding-left: 0;
    width: 100%;
    max-width: 100%;
  }

  .button-container {
    text-align: right;
  }

  .title-content-text {
    color: #FFF;
  }

  .title-action-button {
		text-align: right;
		margin-top: 20px;
	}

  .content-view-mode-text {
    font-size: 16px;
  }

  .content-view-mode-toggle {
    margin-top: 0;
    font-size: 25px;
    font-family: "Roboto Bold";
  }

  .title-content {
    margin: 0px auto;
    display: block;
  }

  .radio-container {
		display: flex;
		flex-direction: row;
	}

  .radio-button,
	.radio-icon {
		margin-right: 5px;
	}

  .input-item-custom::part(native) {
		padding-left: 0;
	}

  .content-name-container {
    display: block;
    padding: 0 5px;
  }

  .title-content-avatar-image {
		border: 3px solid white;
    background: #FFF;
		width: 85px;
		height: 85px;
	}

  @media (min-width: 768px) {
    .content-container-card,
    .button-container {
      width: 700px;
      margin: 25px auto;
    }

    .title-content {
      width: 700px;
      display: flex;
    }
    
    .title-content-name,
    .content-card-title h1 {
      font-size: 26px;
    }

    .content-card-data,
    .card-action-title {
      font-size: 20px;
    }

    .card-action-container,
    .content-name-container {
      display: flex;
    }

    .content-name-container { 
      padding: initial;
    }

    .card-action-button { 
      text-align: right;
    }

    .content-view-mode-toggle {
      margin-top: 30px;
    }

    .toggler-button {
      position: relative;
      left: 0;
    }
  }
</style>