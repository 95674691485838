<template>
  <ion-page>
    <Navbar />
    <ion-content :fullscreen="true">
      <ProfileContainer v-model:getUserInfo.sync="getUserInfo" v-model:isPageLoading.sync="isPageLoading"/>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import Navbar from '@/components/partials/Navbar.vue';
import ProfileContainer from '@/components/ProfileContainer.vue';
import store from '../store/index';
import VueCookies from 'vue-cookies';
import CryptoJS from 'crypto-js';

export default  {
  name: 'profile',
  data() {
    return {
      getUserInfo: {},
      isPageLoading: false
    }
  },
  components: { ProfileContainer, IonContent, IonPage, Navbar },
  created() {
    let parsed_user = {};
    let bytes = "";
    let decryptedData = "";

    let cipherData = VueCookies.get('misc');

    if (cipherData === null  || cipherData === undefined || cipherData === '') {
      window.location.href = "/login";
    } else {
      bytes = CryptoJS.AES.decrypt(cipherData, process.env.VUE_APP_DCRPT_KEY);
      decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (decryptedData) {
        let decode = JSON.parse(decryptedData);
        let user = decode.user;
      
        //Check if user exists
        if (user) {
          this.getUserInfo = user;

          if (user.employee_no === null || user.employee_no === "" || user.employee_no === undefined) {
            this.$router.push('/onboard')
          } else {
            return;
          }
        } else {
          store.dispatch('login/verifyAuth').then(() => {
            this.isPageLoading = false;
            this.getUserInfo = JSON.parse(store.getters['login/currUser']);
            return;
          }).catch(err => {
            if (err.status == 401) {
              localStorage.setItem('is_session_expired', true);
              localStorage.removeItem("is_consent_agreed");
              VueCookies.remove("misc");
              VueCookies.remove("misc-hs");
              window.location.href = '/login';
            }
          });

          if (parsed_user.employee_no === null || parsed_user.employee_no === "" || parsed_user.employee_no === undefined) {
            this.$router.push('/onboard')
          } else {
            return;
          }
        }
      } else {
        localStorage.setItem('is_session_expired', true);
        localStorage.removeItem("is_consent_agreed");
        VueCookies.remove("misc");
        VueCookies.remove("misc-hs");
        window.location.href = '/login';
      }
    }
  }
}
</script>